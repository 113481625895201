import { graphql } from 'gatsby';
import * as _ from 'lodash/fp';
import * as React from 'react';
import Helmet from 'react-helmet';
import { Box, Grommet, Text, Anchor, Heading, Grid, Carousel } from 'grommet';
import { connect } from 'react-redux';


import siteConfig from '../../site-config';
const { colors } = siteConfig;

import Layout from '../components/Layout';

import {
  Layer,
  // Form,
  Calendar,
  Button,
  CheckBox,

} from 'grommet';
import {
  Form,
  validators,
  TextInputField,

  NumberInputField,
  TextAreaField,

} from 'grommet-controls';

import { bindActionCreators } from 'redux';

import styled from 'styled-components';

import Actions from '../actions'


const Error = styled(Text)`
margin-left: 12px;
margin-right: 12px;
margin-top: 6px;
margin-bottom: 6px;
font-size: 18px;
line-height: 24px;
color:#FF4040;`

const CheckBoxWrapper = styled(Box)`
  label {
      align-items: start;
  }
`

const StickyButtons = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.9);

  button {
      border-radius: 0px;
  }
`
const StyledForm = styled(Form)`
  display: ${props => props.hidden ? 'none' : 'block'};
  overflow-y: ${props => props.isSmall ? 'scroll' : 'hidden'} ;
  padding-bottom: 100px;
  
  label { 
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 16px;
  }
  textarea { 
    &::placeholder { 
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 0.5px;
      font-size: 16px;
    }
  }

  & > div > div {
      flex-basis: 100%;
  } 
`

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}



interface BlogIndexProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        description: string;
      };
    };
    allMarkdownRemark: {
      edges: any;
    };
    allImageSharp: {
      edges: any;
    };
  };
}




class ContactUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarInvalid: false,
    };


    this._onFormUpdate = this._onFormUpdate.bind(this);
    this._onDateSelect = this._onDateSelect.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._onPromotionsToggle = this._onPromotionsToggle.bind(this);


  }

  componentDidMount() {
    // console.info("SCROLL")
    // window.scrollTo(0, 0)
  }

  _handleSubmit() {

    const {
      contactForm,
      formSuccessAction,
    } = this.props;


    const { date, dates } = contactForm;
    if (!date && !dates) {
      return this.setState({ calendarInvalid: true })
    }


    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.props.contactForm })
    })
      .then(() => formSuccessAction())
      .catch(error => alert(error));
  }


  _onFormUpdate(data) {
    this.props.updateContactFormData(data);

  }

  _onDateSelect(dateValue) {
    if (!dateValue) return this.setState({ calendarInvalid: true });
    const date = typeof dateValue === 'string' ? dateValue : dateValue[0][0];
    const dates = typeof dateValue !== 'string' ? dateValue : null;

    const newFormData = Object.assign({},
      this.props.contactForm,
      {
        date,
        dates
      }
    )

    this.props.updateContactFormData(newFormData)
    this.setState({ calendarInvalid: false });
  }

  _onPromotionsToggle() {
    this.props.toggleContactPromotionsCheckbox()
  }

  _getFormSnippet(hidden = false) {
    const { calendarInvalid } = this.state;
    const {
      isLarge,
      isSmall,
      contactForm,
      isMobilePortrait,
      dismisscontactLayer,
    } = this.props;


    const { date, dates, promotions } = contactForm;
    // console.info({ calendarInvalid, date, dates })


    const CalendarBox = () => (
      <Box pad={isMobilePortrait ? 'medium' : 'small'} justify='center' style={{ minWidth: isLarge ? "400px" : "200px" }}>

        <Calendar
          size={isLarge ? "medium" : "small"}
          range
          onSelect={this._onDateSelect}
          date={!dates ? date : undefined}
          dates={dates}
        />
        {calendarInvalid && <Error>Dates are required</Error>}
        <input type="hidden" name="date" value="" />
        <input type="hidden" name="dates" value="" />
      </Box>
    )
    console.info({ isMobilePortrait })

    // const FormWrapper = ({Component, ...props}) => {
      
    //   <Component {...props} />
    // }
    // console.info({ formStyle })

    return (

      <StyledForm
        hidden={hidden}
        isSmall={isSmall}
        object={contactForm}
        onInvalidForm={
          (invalidForm) => this._onFormUpdate(invalidForm)
        }
        onValidForm={
          (validForm) => this._onFormUpdate(validForm)
        }
        onChange={
          (onChange) => {
            console.info({ onChange })
          }
        }
        onSubmit={
          this._handleSubmit
        }
        name="contact" id='contact' method="POST" data-netlify="true" data-netlify-honeypot="bot-field"
      >
        <form
                  name="contact" id='contact' method="POST" data-netlify="true" data-netlify-honeypot="bot-field"
>
        {/* You still need to add the hidden input with the form name to your JSX form */}
        <div hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field" />
          </label>
        </div>
        <input type="hidden" name="form-name" value="contact" />
        <Box direction='row' pad={{ vertical: 'small' }} className='wrapper'>
          <Box basis={isLarge ? '1/2' : '100%'} pad={{ vertical: 'small' }} align={'start'}>
            <TextInputField
              label='Name'
              name='name'
              validation={[validators.required(), validators.minLength(4)]}
            />

            <TextInputField
              label="Email"
              name="email"
              validation={[validators.required(), validators.email()]}

            />

            <TextInputField
              label="Phone"
              name="phone"
              validation={[validators.required(), validators.numeric()]}

            />

            <NumberInputField
              label="Adults"
              name="adults"
              min={1}
              max={4}
              validation={[validators.required()]}

            />
            {isMobilePortrait && <CalendarBox />}
          </Box>
          {/* Calendar not Portrait */}
          {!isMobilePortrait && <CalendarBox />}

        </Box>
        <Box >
          <CheckBoxWrapper style={{ maxWidth: '960px' }}>

            <CheckBox
              checked={promotions}
              label={
                <Box align='start'>
                  <Text size={"small"}>
                    {"Would you like to subscribe for our newsletter and promotional emails?"}

                  </Text>
                  <Text size={"xsmall"}>{"We hate spam too and will never pass any of your details to anyone."}</Text>
                </Box>

              }
              name="promotions"
              onChange={this._onPromotionsToggle}
            // validation={[validators.required()]}

            />

          </CheckBoxWrapper>
          <Box pad={{ vertical: 'small' }}>

            <TextAreaField label='Comments' name='comments' placeholder={"Please feel free to ask or tell us anything here"} />
          </Box>
        </Box>

        <Box direction='row' flex='grow' pad={{ vertical: 'medium' }} alignContent='between'>
          <Box basis='2/4'>

            <Button type="submit" primary label="Submit" style={{ borderRadius: '0', textTransform: 'uppercase' }} />
          </Box>

        </Box>
        </form>

      </StyledForm>

    )
  }

  render() {
    const {
      getHiddenForm,
      data,
      isLarge
    } = this.props;

    if (getHiddenForm) {
      return this._getFormSnippet(true)
    }
    const siteTitle = data.site.siteMetadata.title;
    const siteDescription = data.site.siteMetadata.description;

    // const is
    return (
      <Layout >
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`${siteTitle} - Contact Us`}>
          <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
          <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500" />
        </Helmet>

        <main>
          <Box direction='row'>
            {isLarge && <Box basis='1/3'></Box>
            }            <Box flex margin={{ vertical: 'small' }}>
              <Box pad={{ horizontal: 'large' }} style={{ background: 'white' }}>
                <Heading level='1' style={{ textTransform: 'uppercase', fontWeight: '400' }} margin={{ vertical: 'small' }}>Contact Us</Heading>
                <Text>Feel feel free book or ask us anything :)</Text>
                {this._getFormSnippet()}
              </Box>
            </Box>
          </Box>
        </main>
      </Layout>



    );

  }


}



//   return (
//     <Layout location={location}>
//       <Helmet
//         htmlAttributes={{ lang: 'en' }}
//         meta={[{ name: 'description', content: siteDescription }]}
//         title={siteTitle}>
//         <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
//         <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
//         <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500" />
//       </Helmet>

//       <main>

//       </main>
//     </Layout>
//   );
// };



const mapStateToProps = ({ themeType, contactForm,
  browser }) => {

  const isSmall = browser && ['small', 'extraSmall'].indexOf(browser.mediaType) > -1;
  const isLarge = browser && browser.greaterThan.medium;

  const isPortrait = browser && browser.orientation === 'portrait';
  const isMobilePortrait = isSmall && isPortrait;
  return {
    isSmall,
    isLarge,
    isPortrait,
    isMobilePortrait,
    contactForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Actions, dispatch)
};

const ConnectedBlogIndex = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUs);



export default ConnectedBlogIndex;



export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

          // fluid(maxHeight: 500) {
          //   ...GatsbyImageSharpFluid
          // }