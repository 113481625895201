import { Box, Markdown, Text, Anchor } from 'grommet';
import * as React from 'react';
import ReactScroll from 'react-scroll'
import siteConfig from '../../site-config';
import styled from 'styled-components';
import { IconButton } from 'grommet-controls';
import { Up } from 'grommet-icons';


const Scroll = ReactScroll.animateScroll
const { colors } = siteConfig;


const StyledIconButton = styled(IconButton)`
  button { background: ${colors.accent}}

  &:hover {
    background: ${colors.accent}
  }  
  & > svg  {
    stroke: white;
    width: 24px;
    height: 24px;

  } 
`
const Header = styled(Box)`

  width: 100%;

  & > div:first-child{
    width: 100%;
    min-height:200px;
    background: ${colors.brand};
    color: white;

  }

  & > div:nth-child(2){
    width: 100%;
    min-height:25px;
    background: rgba(255,255,255,0.9);
  }

`;
export default () => (
  <footer>
    <Header align="center" flex='grow' fill='horizontal'>

      <Box flex='grow' align='center' justify='center'>
        <Box flex='grow' align='center' justify='center'>
          <StyledIconButton icon={<Up />} onClick={() => Scroll.scrollToTop()} />
          <Anchor onClick={() => Scroll.scrollToTop()} color='white' style={{textTransform: 'uppercase', fontWeight: '400' }}>
            Back To Top
        </Anchor>
        </Box>

      </Box>
      <Box flex='grow' align='center' justify='center'><Text size='xsmall'>All Rights Reserved 2020</Text></Box>
      {/* <Text color="text">
        <Markdown>{siteConfig.footerText}</Markdown>
      </Text> */}
    </Header>
  </footer>
);
