
import { calculateResponsiveState } from 'redux-responsive'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import objPath from 'object-path'


const showBookingLayer = () => (dispatch, getState) => {
    trackCustomEvent({
        category: "Booking Layer",
        action: "Opened",
        label: "Booking Layer Opened"
    })
    dispatch({
        type: 'SHOW_BOOKING_LAYER'
    })
}

const dismissBookingLayer = () => (dispatch, getState) => {
    trackCustomEvent({
        category: "Booking Layer",
        action: "Closed",
        label: "Booking Layer Closed"
    })
    dispatch({
        type: 'DISMISS_BOOKING_LAYER'
    })
}


const toggleMenuLayer = () => (dispatch, getState) => {
    dispatch({
        type: 'TOGGLE_MENU_LAYER'
    })

    // console.info(objPath.get(getState(), 'main.menuLayerVisible'))
    const menuOpened = objPath.get(getState(), 'main.menuLayerVisible')
    const menuText = menuOpened ? "Opened" : "Closed";
    trackCustomEvent({
        category: "Menu Layer",
        action: menuText,
        label: `Booking Layer ${menuText}`
    })

}
const togglePromotionsCheckbox = (dispatch) => ({
    type: 'TOGGLE_BOOKING_PROMOTIONS'
})


const updateFormData = (formObject) => (dispatch) => dispatch({
    type: 'UPDATE_FORM_DATA',
    formObject
})

const updateContactFormData = (formObject) => (dispatch) => dispatch({
    type: 'UPDATE_CONTACT_FORM_DATA',
    formObject
})

const toggleContactPromotionsCheckbox = (dispatch) => ({
    type: 'TOGGLE_CONTACT_PROMOTIONS'
})

const formSuccessAction = (booking = false) => (dispatch) => {

    const formType = booking ? 'BOOKING_FORM_SUCCESS' : 'CONTACT_FORM_SUCCESS';

    dispatch({
        type: formType,
    })


    trackCustomEvent({
        category: "Forms",
        action: "Form Sent",
        label: `${formType}`
    })

}

const dismissFormSuccess = () => (dispatch) => dispatch({
    type: 'DISMISS_FORM_SUCCESS',
})

const updateResponsiveState = () => dispatch =>
    dispatch(calculateResponsiveState(window))

const windowScrollTop = () => dispatch =>
    dispatch({
        type: 'WINDOW_SCROLL_TOP'
    })




export default {
    updateFormData,
    toggleMenuLayer,
    windowScrollTop,
    showBookingLayer,
    dismissBookingLayer,
    updateResponsiveState,
    togglePromotionsCheckbox,
    updateContactFormData,
    formSuccessAction,
    dismissFormSuccess,
    toggleContactPromotionsCheckbox

}