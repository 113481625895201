import {
    Box,
    Heading,
    Layer,
    // Form,
    Text,
    Button

} from 'grommet';
import { Link } from 'gatsby';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Fragment } from 'react';


import Actions from '../actions'



class FormLayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        const {
            formSuccess,
            dismissFormSuccess,
        } = this.props;


        // const is
        return (
            <Box flex >
                {
                    formSuccess.display &&
                    <Layer
                        style={{zIndex: 12}}
                        margin='small'
                        onEsc={() => dismissFormSuccess()}
                    // onClickOutside={() => dismissBookingLayer()}
                    >
                        <Box pad='large' responsive>
                            <Heading level='1' style={{ textTransform: 'uppercase', fontWeight: '400' }}>Thank You</Heading>
                            <Text>We received your message and we will get back to you very soon.</Text>
                            <Box pad='large' flex align='center'>
                                <Link to='/' style={{display: 'flex', minWidth: '200px'}}>
                                    <Button
                                        style={{ borderRadius: '0', width: '100%' }}
                                        type="reset" primary label="Close"
                                        // href='/'
                                        onClick={() => dismissFormSuccess()} />
                            </Link>

                            </Box>
                        </Box>
                    </Layer>
                }

            </Box>

        );

    }


}




const mapStateToProps = ({ main, formSuccess, browser }) => {


    const isSmall = browser && ['small', 'extraSmall'].indexOf(browser.mediaType) > -1;
    const isPortrait = browser && browser.orientation === 'portrait';
    const isMobilePortrait = isSmall && isPortrait;
    return {
        formSuccess,
        isSmall,
        isPortrait,
        isMobilePortrait,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(Actions, dispatch)
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormLayer);
