import {
    Box,
    Heading,
    Layer,
    // Form,
    Calendar,
    Text,
    Button,
    CheckBox,

} from 'grommet';
import {
    Form,
    validators,
    TextInputField,

    NumberInputField,
    TextAreaField,

} from 'grommet-controls';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Fragment } from 'react';

import styled from 'styled-components';

import Actions from '../actions'


const Error = styled(Text)`
margin-left: 12px;
margin-right: 12px;
margin-top: 6px;
margin-bottom: 6px;
font-size: 18px;
line-height: 24px;
color:#FF4040;`

const CheckBoxWrapper = styled(Box)`
    label {
        align-items: start;
    }
`

const StickyButtons = styled(Box)`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(255,255,255,0.9);

    button {
        border-radius: 0px;
    }
`
const StyledForm = styled(Form)`
    display: ${props => props.hidden ? 'none' : 'block'};
    overflow-y: scroll;
    padding-bottom: 100px;

    label { 
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 1px;
        font-size: 16px;
      }
      textarea { 
        &::placeholder { 
          text-transform: uppercase;
          font-weight: 400;
          letter-spacing: 0.5px;
          font-size: 16px;
        }
      }
    
    & > div > div {
        flex-basis: 100%;
    } 
`

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}


class FormLayer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            calendarInvalid: false,
        };


        this._onFormUpdate = this._onFormUpdate.bind(this);
        this._onDateSelect = this._onDateSelect.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
        this._onPromotionsToggle = this._onPromotionsToggle.bind(this);


    }

    componentDidMount() {
        // console.info("SCROLL")
        // window.scrollTo(0, 0)
    }

    _handleSubmit() {

        const {
            bookingForm,
            dismissBookingLayer,
            formSuccessAction,

        } = this.props;


        const { date, dates } = bookingForm;
        if (!date && !dates) {
            return this.setState({ calendarInvalid: true })
        }


        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "booking", ...this.props.bookingForm })
        })
            .then(() => {
                dismissBookingLayer()
                formSuccessAction(true)
            })
            .catch(error => alert(error));
    }


    _onFormUpdate(data) {
        this.props.updateFormData(data);

    }

    _onDateSelect(dateValue) {
        if (!dateValue) return this.setState({ calendarInvalid: true });
        const date = typeof dateValue === 'string' ? dateValue : dateValue[0][0];
        const dates = typeof dateValue !== 'string' ? dateValue : null;

        const newFormData = Object.assign({},
            this.props.bookingForm,
            {
                date,
                dates
            }
        )

        this.props.updateFormData(newFormData)
        this.setState({ calendarInvalid: false });
    }

    _onPromotionsToggle() {
        this.props.togglePromotionsCheckbox()
    }

    _getFormSnippet(hidden = false) {
        const { calendarInvalid } = this.state;
        const {
            isSmall,
            bookingForm,
            isMobilePortrait,
            dismissBookingLayer,
        } = this.props;


        const { date, dates, promotions } = bookingForm;
        // console.info({ calendarInvalid, date, dates })


        const CalendarBox = () => (
            <Box pad={isMobilePortrait ? 'medium' : 'small'} justify='center' style={{ minWidth: '200px' }}>

                <Calendar
                    size="small"
                    range
                    onSelect={this._onDateSelect}
                    date={!dates ? date : undefined}
                    dates={dates}
                />
                {calendarInvalid && <Error>Dates are required</Error>}
                <input type="hidden" name="date" value="" />
                <input type="hidden" name="dates" value="" />
            </Box>
        )
        console.info({ isMobilePortrait })


        // console.info({ formStyle })

        return (

            <StyledForm
                hidden={hidden}
                isSmall={isSmall}
                object={bookingForm}
                onInvalidForm={
                    (invalidForm) => this._onFormUpdate(invalidForm)
                }
                onValidForm={
                    (validForm) => this._onFormUpdate(validForm)
                }
                onChange={
                    (onChange) => {
                        console.info({ onChange })
                    }
                }
                onSubmit={
                    this._handleSubmit
                }
                name="booking" id='booking' method="POST" data-netlify="true" data-netlify-honeypot="bot-field"
            >
                {/* <form
                    action="/"
 > */}
                {/* You still need to add the hidden input with the form name to your JSX form */}
                <div hidden>
                    <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" />
                    </label>
                </div>
                <input type="hidden" name="form-name" value="booking" />
                <Box direction='row' pad={{ vertical: 'small' }} className='wrapper'>
                    <Box basis={isSmall ?'100%' : '1/2'} pad={{ vertical: 'small' }} align={ 'start'}>
                        <TextInputField
                            label='Name'
                            name='name'
                            validation={[validators.required(), validators.minLength(4)]}
                        />

                        <TextInputField
                            label="Email"
                            name="email"
                            validation={[validators.required(), validators.email()]}

                        />

                        <TextInputField
                            label="Phone"
                            name="phone"
                            validation={[validators.required(), validators.numeric()]}

                        />

                        <NumberInputField
                            label="Adults"
                            name="adults"
                            min={1}
                            max={4}
                            validation={[validators.required()]}

                        />
                        {isMobilePortrait && <CalendarBox />}
                    </Box>
                    {/* Calendar not Portrait */}
                    {!isMobilePortrait && <CalendarBox />}

                </Box>
                <Box >
                    <CheckBoxWrapper pad={{ vertical: 'small' }}>

                        <CheckBox
                            checked={promotions}
                            label={
                                <Box align='start'>
                                    <Text size={"small"}>
                                        {"Would you like to subscribe for our newsletter and promotional emails?"}

                                    </Text>
                                    <Text size={"xsmall"}>{"We hate spam too and will never pass any of your details to anyone."}</Text>
                                </Box>

                            }
                            name="promotions"
                            onChange={this._onPromotionsToggle}
                        // validation={[validators.required()]}

                        />

                    </CheckBoxWrapper>
                    <TextAreaField label='Comments' name='comments' placeholder={"Please feel free to ask or tell us anything here"} />

                </Box>

                <StickyButtons direction='row' flex='grow' pad='medium' alignContent='between'>
                    <Box basis='2/4'>

                        <Button type="submit" primary label="Submit" />
                    </Box>
                    <Box flex></Box>
                    <Box>
                        <Button label="Cancel" onClick={() => dismissBookingLayer()} />
                    </Box>


                </StickyButtons>
                {/* </form> */}

            </StyledForm>

        )
    }

    render() {
        const {
            getHiddenForm,
            dismissBookingLayer,

            bookingLayerVisible,
        } = this.props;

        if (getHiddenForm) {
            return this._getFormSnippet(true)
        }

        // const is
        return (
            <Box flex >
                {
                    bookingLayerVisible &&
                    <Layer
                        margin='small'
                        onEsc={() => dismissBookingLayer()}
                    // onClickOutside={() => dismissBookingLayer()}
                    >
                        <Box pad='small' flex>
                            <Box pad={{ horizontal: 'large' }}>
                                <Heading style={{ textTransform: 'uppercase', fontWeight: '400' }} margin={{ vertical: 'small' }}>Book Your Stay</Heading>
                                {this._getFormSnippet()}
                            </Box>
                        </Box>
                    </Layer>
                }

            </Box>

        );

    }


}




const mapStateToProps = ({ main, bookingForm, browser }) => {
    const { bookingLayerVisible } = main;


    const isSmall = browser && ['small', 'extraSmall'].indexOf(browser.mediaType) > -1;
    const isPortrait = browser && browser.orientation === 'portrait';
    const isMobilePortrait = isSmall && isPortrait;
    return {
        isSmall,
        isPortrait,
        isMobilePortrait,
        bookingForm,
        bookingLayerVisible,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(Actions, dispatch)
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormLayer);
