module.exports = {
  //
  // Change information to your own:
  //
  // Site Info:
  title: 'Noya Tel Aviv - Travel Like You Live',
  description: 'We make your Tel Aviv experience incredible',
  siteUrl: 'https://www.noyatlv.com',

  // Services:
  googleAnalyticsTrackingId: `UA-46753063-3`,

  // Site Style:
  lightTheme: 'grommet', // grommet | dark | base | dxc | aruba
  // leave darkTheme blank if you don’t need a theme switch
  darkTheme: 'dark', // '' | grommet | dark | base | dxc | aruba
  font: 'Arial', // Roboto
  fontHeadings: '', // Merriweather
  brandColor: '', // #7D4CDB

  colors: {
    // brand: '#480032',
    brand: '#333b4e',
    accent: '#480032',
    greywhite: '#f4f4f4',
    grey: 'rgba(0,34,51,.6)'
  }
};
