import { Link } from 'gatsby';
import { Box, Heading, Anchor, Layer, Stack, Button } from 'grommet';
import { IconButton } from 'grommet-controls';
import { Menu, FormClose } from 'grommet-icons';
import styled from 'styled-components';

import React, { Fragment } from 'react';
import Media from 'react-media';
import { useMediaQuery } from 'react-responsive'
import siteConfig from '../../site-config';
import HomeIcon from '../assets/svg-icons/home.inline.svg'
import AboutIcon from '../assets/svg-icons/about.inline.svg'
import ContactIcon from '../assets/svg-icons/contact.inline.svg'
import WhatsappIcon from '../assets/svg-icons/whatsapp.inline.svg'

import NoyaIcon from '../assets/svg-icons/noya.inline.svg'
import { isAbsolute } from 'path';

// Slider
const { colors } = siteConfig;

const IconBox = styled(Box)`
display: block;
position: fixed;
bottom: 12px;
right: 12px;
z-index:11;
background: ${colors.accent};
border-radius: 48px;
width: 48px;
height: 48px;
box-shadow: 0px 0px 15px 1px rgba(0,0,0, 0.3);
`
const StyledIconButton = styled(IconButton)`
  & > svg  {
    stroke: rgba(51, 59, 78,1);
    width: 48px;
    height: 48px;

  } 
`

const MenuContactButtons = styled(Button)`
  & > svg  {
    color: white;
    width: 24px;
    height: 24px;

  } 
`

const MenuDrop = styled(Box)`
  background: rgba(255,255,255,0.99);

  div {
    align-self: center;
  }

  a {
    font-size: 24px !important;
    align-self: center !important;
    padding: ${props => props.isMobilePortrait ? '20px' : '6px'};
  }

`


const MenuItems = styled(Box)`
 & > div > a {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    border-bottom: 2px solid transparent;
    align-self: baseline;
    color: ${colors.brand};

    :hover, &.active {
      text-decoration: none;
      border-bottom: 2px solid ${colors.brand};
    }
    :hover, :visited {
      color: ${colors.brand};
    }
    &.book {
 
     border: 1px solid ${colors.brand};
     padding: 4px 16px;
     
     :hover {
       text-decoration: none;
       background: ${colors.brand};
       color: white;
     }
  
    }
 }
`




const ResponsiveMenu = ({ lessThanLarge, isMobilePortrait, showBookingLayer, toggleMenuLayer, menuLayerVisible }) => {


  const MenuContent = () => (
    <MenuItems
      justify='end' fill='horizontal'>
      <Box
        direction={lessThanLarge ? 'column' : 'row'}
        pad={
          { vertical: "medium", horizontal: 'large' }
        }
        alignContent="between"
        gap="large">
        <Link
          to="/"
          activeClassName="active"
          onClick={() => toggleMenuLayer()}

        >
          Home

        </Link>
        <Link
          to="/location"
          activeClassName="active"
          onClick={() => toggleMenuLayer()}


        >
          Location
        </Link>

        <Link
          to="/the-suite"
          activeClassName="active"
          onClick={() => toggleMenuLayer()}

        >
          Your Suite
        </Link>

        <Link
          activeClassName="active"

          onClick={() => toggleMenuLayer()}

          to="/contact-us" >
          Contact Us
        </Link>

        <Anchor
          className='book'
          onClick={() => toggleMenuLayer()}

          label="Book Now"
          onClick={showBookingLayer}
        />


      </Box>
      <Box>

      </Box>
    </MenuItems>
  )

  const MenuControl = ({ }) => {
    return (
      <Box >
        <StyledIconButton icon={<Menu />} onClick={() => toggleMenuLayer()} />
        {menuLayerVisible && (
          <Layer
            full
            plain
            onEsc={() => toggleMenuLayer()}
            onClickOutside={() => toggleMenuLayer()}
            style={{ zIndex: 12 }}
          >
            <MenuDrop
              isMobilePortrait={isMobilePortrait}
              className="menu-drop"
              fill
              pad='medium'
              align='center'
              justify='start'>
              <StyledIconButton icon={<FormClose />} onClick={() => toggleMenuLayer()} />

              <MenuContent />


            </MenuDrop>
            {/* <Button label="close" onClick={() => setShow(false)} /> */}
          </Layer>
        )}
      </Box>
    );
  }


  return (
    <Box align='end' justify='end' style={{ minWidth: '72px' }}>
      {
        lessThanLarge ? <MenuControl /> : <MenuContent />
      }
    </Box>)

}


const Header = styled(Box)`
background: rgba(255,255,255,0.9);
`;

const Logo = styled(Box)`

  background: ${colors.brand};
  // min-width: 200px;
  &  > h1 {
    display: flex;
    align-items: center;
    // width: 100%;
    
    & > a, & > div {

      color: white;
      white-space:nowrap;
      & > span {
        font-weight: 400;
      }
  }

  & > div {
    align-items: flex-end;
  }
  }
`

export default ({
  browser,
  isSmall,
  isPortrait,
  isMobilePortrait,
  showBookingLayer,
  toggleMenuLayer,
  menuLayerVisible }) => {

  const lessThanLarge = browser.lessThan.large;

  // const iconStyle = {
  //   fill: colors.brand,
  //   width: lessThanLarge ? '36px' : '48px',
  //   padding: lessThanLarge ? '10px' : '12px',
  // }

  const logoProps = {
    pad: 'medium',
    flex: lessThanLarge ? 'grow' : 'shrink',
    basis: lessThanLarge ? 'auto' : '1/3',
    align: lessThanLarge ? 'start' : 'center',
  }

  return (
    <Header
      as="header"
      elevation="medium"
      flex
    // direction='row'
    // width={{ min: 'small', max: "xlarge" }}
    >

      <Box direction='row' flex>

        <Logo {...logoProps}>

          {/* <NoyaIcon style={iconStyle} /> */}
          <Heading textAlign="start" color="brand" margin="small">
            <Anchor
              as={'a'}
              href="/"
            >
              {'NOYA'} <span>{`TLV`}</span>

            </Anchor>

          </Heading>


        </Logo>


        <ResponsiveMenu  {...{ lessThanLarge, isMobilePortrait, showBookingLayer, toggleMenuLayer, menuLayerVisible }} flex />
      </Box>
      <IconBox align='center' flex>
        <MenuContactButtons icon={<WhatsappIcon />} href={'https://wa.me/972547734499?text=Hello%20Noya%20TLV...'} />
      </IconBox>
    </Header >
  );

}
