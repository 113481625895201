import { Box, Grommet, Text, Anchor, Heading, Grid } from 'grommet';
import { Form, DateInputField } from 'grommet-controls';
import { grommet } from 'grommet/themes';
import { createGlobalStyle } from 'styled-components';
import { connect } from 'react-redux';
import { StickyContainer, Sticky } from 'react-sticky';
import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';

import { useMediaQuery } from 'react-responsive'
import siteConfig from '../../site-config';
// import { Stickyroll } from '@stickyroll/stickyroll';
import styled from 'styled-components';
import Img from "gatsby-image"

import siteTheme from '../site-theme';

import SiteFooter from './SiteFooter';
import SiteHeader from './SiteHeader';
import Sidebar from './Sidebar';
import Slider from './Slider';
import CheckoutBanner from './CheckoutBanner';
import BookingBar from './BookingBar';
import FormSuccessLayer from './FormSuccessLayer';

import FormLayer from './FormLayer';
import Actions from '../actions'
import ContactUs from '../pages/contact-us'
import { Animate, AnimateGroup } from 'react-simple-animate';
// import console = require('console');
const GlobalStyle = createGlobalStyle`
  img {
    max-width: 100%;
  }
  body {
    margin: 0;
    background: #f4f4f4;
    overflow-x: hidden;
  }
  a:hover {
    opacity: 0.9;
  }
`;





const LocationBox = styled(Box)`
  .content {
        width: ${props => props.isLarge ? '70vw' : '100%'};
    background: ${siteConfig.colors.brand}
      }
  .heading {
        color: white;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 2px;
    }
  span {
        color: white;
      max-width: 450px;
      font-weight: 300;
    }
  `


class Theme extends React.Component {
  constructor(props) {
    super(props);
    console.info({ props })
  }

  componentDidMount() {
    this.props.updateResponsiveState()
    this.props.windowScrollTop()

  }

  render() {
    const {
      main,
      browser,
      children,
      isSmall,
      isPortrait,
      location,
      isMobilePortrait,
      showBookingLayer,
      toggleMenuLayer
    } = this.props

    return (
      <Grommet theme={siteTheme} style={{ background: '#f4f4f4', overflowX: 'hidden' }}>

        <GlobalStyle />
        <Box >

          <Box
            alignSelf='center'
            width='1440px'
            flex='grow'
            // elevation='small'
            style={{ overflow: 'hidden' }}
          >
            <SiteHeader
            elevation='small'

              toggleMenuLayer={toggleMenuLayer}
              showBookingLayer={showBookingLayer}
              menuLayerVisible={main.menuLayerVisible}
              {...{
                browser,
                isSmall,
                isPortrait,
                isMobilePortrait,
                location
              }}
            />
            <Box flex='grow'>

              {children}
            </Box>

            <SiteFooter />

          </Box>
          <FormLayer browser={browser} />
          <FormLayer getHiddenForm />
          <FormSuccessLayer />
          <ContactUs getHiddenForm />
        </Box>

      </Grommet >
    );

  }


}




const mapStateToProps = ({ main, browser }) => {

  const isSmall = browser && ['small', 'extraSmall'].indexOf(browser.mediaType) > -1;
  const isPortrait = browser && browser.orientation === 'portrait';
  const isMobilePortrait = isSmall && isPortrait;
  return {
    isSmall,
    isPortrait,
    isMobilePortrait,
    main,
    browser
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Actions, dispatch)
};

const ConnectedTheme = connect(
  mapStateToProps,
  mapDispatchToProps
)(Theme);

export default function Layout({ children, ...rest }) {
  return <ConnectedTheme {...rest}>{children}</ConnectedTheme>;
}
