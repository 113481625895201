import siteConfig from '../site-config';

const { colors } = siteConfig;
// https://github.com/grommet/grommet/wiki/Grommet-v2-theming-documentation
// https://github.com/grommet/grommet/tree/NEXT/src/js/themes

// https://colorhunt.co/palette/141682
export default {
  global: {
    font: {
      family: 'Roboto Slab'
    },
    background: {
      color: 'red',
    },
    colors: colors,
    breakpoints: {
      xsmall: {
        value: 500
      },
      small: {
        value: 1000
      },
      medium: {
        value: 1200
      },
      middle: {
        value: 3000
      }
    }
  },
  formField: {
    label: {
      size: "small",
      margin: { vertical: "small", bottom: "0", horizontal: "0" },
      weight: 600
    },
    // border: false,
    margin: 0
  },
  heading: {
    font: {
      family: siteConfig.fontHeadings
    }
  }
};
